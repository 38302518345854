<template>
  <div class="build-div">
    <div class="title-div" v-if="category == '2'">
        {{tableData2.name || '未知'}}
    </div>
    <div class="title-div" v-if="category == '3'">
        {{tableData.name || '未知'}}
    </div>
    <div class="card">
         <div class="item background1" v-if="category == '2'">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总楼栋数量:</div>
                      <div class="num">{{dataTotal || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">已入驻楼栋长数量: {{yidataTotal || 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="yidataTotal && yidataTotal > 0 ? ((yidataTotal/dataTotal) * 100) > 100 ? 100 : ((yidataTotal/dataTotal) * 100) :0" :stroke-color="['#41ABF7', '#ccd3f8']">
                       <Icon type="checkmark-circled"></Icon>
                            <span>{{yidataTotal && yidataTotal > 0 ? ((yidataTotal/dataTotal) * 100) > 100 ? 100 : (((yidataTotal/dataTotal) * 100)|filtersNum) :0}}%</span>
                  </Progress>
              </div>
          </div>
         <div class="item background1" v-if="category == '3'">
              <div class="top-div">
                  <div class="div-left">
                      <div class="title">总户数代表:</div>
                      <div class="num">{{tableData.total || 0}} <span class="span">个</span></div>
                  </div>
                  <div class="div-right">
                      <div class="num1">已入驻户代表: {{tableData.num || 0}}</div>
                  </div>
              </div>
              <div class="bottom-div">
                  <Progress :percent="tableData.num && tableData.num > 0 ? ((tableData.num/tableData.total) * 100) > 100 ? 100 : ((tableData.num/tableData.total) * 100) :0" :stroke-color="['#41ABF7', '#ccd3f8']">
                       <Icon type="checkmark-circled"></Icon>
                            <span>{{tableData.num && tableData.num > 0 ? ((tableData.num/tableData.total) * 100) > 100 ? 100 : (((tableData.num/tableData.total) * 100) |filtersNum) :0}}%</span>
                  </Progress>
              </div>
          </div>
            <!-- <Card v-if="category == '2'">
                <div class="title">总楼组数量:{{dataTotal || 0}}</div>
                <div class="title">&nbsp;</div>
                <div class="title">已入驻楼(组)长数量：{{yidataTotal || 0}}</div>
        </Card>
            <Card v-if="category == '3'">
                <div class="title">总户数代表:{{tableData.total}}</div>
                <div class="title">&nbsp;</div>
                <div class="title">已入驻户代表：{{tableData.num || 0}}</div>
        </Card> -->
        
    </div>
    <div class="center">
          <div class="desc">图像说明:</div>
          <div class="white" v-if="category == '2'">
              <div class="left"></div>
              <div class="right">
                  <div>{{dataTotal - yidataTotal > 0 ?dataTotal - yidataTotal : 0}}</div>
                  <div>未入驻楼组</div>
              </div>
          </div>
          <div class="white" v-if="category == '3'">
              <div class="left"></div>
              <div class="right">
                  <div>{{tableData.total - tableData.num > 0 ?tableData.total - tableData.num:0}}</div>
                  <div>未入驻户数</div>
              </div>
          </div>
          <!-- <div class="red">
               <div class="left"></div>
                <div class="right">
                    <div>0</div>
                    <div>已入驻网格员</div>
                </div>
          </div> -->
          <div class="red" v-if="category == '2'">
                <div class="left"></div>
                <div class="right">
                    <div>{{yidataTotal || 0}}</div>
                    <div>已入驻楼栋长</div>
                </div>
          </div>
          <div class="yellow" v-if="category == '2'">
              <div class="left"></div>
                <div class="right">
                    <div>{{jumingTotal || 0}}</div>
                    <div>已入驻户代表</div>
                </div>
          </div>
          <div class="yellow" v-if="category == '3'">
              <div class="left"></div>
                <div class="right">
                    <div>{{tableData.num || 0}}</div>
                    <div>有户代表</div>
                </div>
          </div>
          <!-- <div class="red" v-if="category == '3'">
              <div class="left"></div>
                <div class="right">
                    <div>{{tableData.num || 0}}</div>
                    <div>无户代表</div>
                </div>
          </div> -->
      </div>
       <div class="bottom">
           <div class="bottom-child">
                <div class="child" v-for="(item,index) in tableData2.childs" :key="index">
                    <div :class="item.nodeManager > 0 ? 'top child-top red' :'top'">
                        <div class="name">楼栋名称： {{item.name | spliceTitle}}</div>
                        <!-- <div class="build">楼栋共有：{{item.childs ? (item.childs | spliceNum) : 0}}</div> -->
                        <div class="builed">已有楼栋长：{{item.nodeManager ? item.nodeManager : 0}}</div>
                    </div>
                    <div class="child-view">
                        <div @click='open(items.code)' :class="items.category != '5' ? 'build-view' : ''" v-for="(items,indexs) in item.childs" :key="indexs" op>
                            <template v-if="items.category != '5'">
                                <div :class="items.nodeManager ?'item yellow':'item'">
                                    <div class="image">
                                        <img class="img" :src="buildimage" alt="" />
                                    </div>
                                    <div class="text">
                                        <div class="title">{{items.name | spliceTitle}}</div>
                                        <div class="title2">{{items.nodeManager ? '已入驻' : '未入驻'}}</div>
                                        </div>
                                </div>
                                <div class="text-view">
                                    <!-- <div class="text-item">
                                        <div class="title">楼总户数</div>
                                        <div class="num"  >{{items.childs ? items.childs.length : 0}}</div>
                                    </div> -->
                                    <div class="text-item">
                                        <div class="title">户代表数</div>
                                        <div class="num"  >{{items.nodeManager ? items.nodeManager : 0}}</div>
                                    </div>
                                    <!-- <div class="text-item">
                                        <div class="title">无户代表</div>
                                        <div class="num"  >{{(items.childs - items.nodeManager) > 0  ? (items.childs - items.nodeManager) : 0}}</div>
                                    </div> -->
                                </div>
                                <!-- <div class="precent">
                                    <Progress :percent="items.nodeManager != 0 ? ((items.nodeManager/items.childs.length) * 100) > 100 ? 100 : ((items.nodeManager/items.childs.length) * 100) : 0 " :stroke-color="['#59be94', '#d1f5e6']">
                                    <Icon type="checkmark-circled"></Icon>
                                            <span style="color:#999999 ">{{items.nodeManager != 0 ? ((items.nodeManager/items.childs.length) * 100) > 100 ? 100 : (((items.nodeManager/items.childs.length) * 100)|filtersNum) : 0}}%</span>
                                    </Progress>
                                </div>     -->
                            </template>
                        </div>
                    </div>
                </div>
            </div>
           <!-- <template v-if="category == '2'">
               <div class="bottom-view" v-for="(item,index) in tableData2.childs" :key="index">
                <div :class="item.manager > 0 ?'left leftnone':'left'">{{item.name}}</div>
                <div class="right">
                    <div :class="items.manager ?'item yellow':'item'"  v-for="(items,indexs) in item.childs" :key="indexs" @click="openModal(items)">
                        <div class="content">{{items.name}}</div>
                    </div>
                </div>
          </div>  
           </template> -->
         
          <!-- <div class="bottom-view" v-if="category == '3'">
              <div class="left">{{tableData.name}}</div>
                <div class="right">
                    <div :class="item.nodeManager ?'item yellow':'item'"   v-for="(item,index) in tableData.childs" :key="index">
                        <div class="content">{{item.name}}</div>
                    </div>
                </div>
          </div>   -->
          <div class="bottom-child" v-if="category == '3'">
            <div class="child">
                <!-- <div :class="tableData.manager > 0 ? 'top child-top' :'top'">
                    <div class="name">楼组名称： {{tableData.name | spliceTitle}}</div>
                    <div class="build">户数共有：{{tableData.childs ? (tableData.childs | spliceNum) : 0}}</div>
                    <div class="builed">已有户代表：{{tableData.nodeManager ? tableData.nodeManager : 0}}</div>
                </div> -->
                <div class="child-view">
                    <div class="build-view" v-for="(items,index) in tableData.childs" :key="index">
                        <template>
                            <div :class="items.nodeManager ?'item yellow':'item'">
                                <div class="image">
                                    <img class="img" :src="buildimage" alt="" />
                                </div>
                                <div class="text">
                                    <div class="title">{{items.name}}</div>
                                    <div class="title2">{{items.nodeManager ? '已入驻' : '未入驻'}}</div>
                                </div>
                            </div>
                            <div class="text-view">
                            <!-- <div class="text-item">
                                <div class="title">楼总户数</div>
                                <div class="num"  >{{items.childs ? items.childs.length : 0}}</div>
                            </div> -->
                            <div class="text-item">
                                <div class="title">户代表数</div>
                                <div class="num"  >{{items.nodeManager ? items.nodeManager : 0}}</div>
                            </div>
                            <!-- <div class="text-item">
                                <div class="title">无户代表</div>
                                <div class="num"  >{{(items.childs - items.nodeManager) > 0  ? (items.childs - items.nodeManager) : 0}}</div>
                            </div> -->
                        </div>
                            <!-- <div class="precent">
                                <Progress :percent="items.nodeManager != 0 ? ((items.nodeManager/items.childs.length) * 100) > 100 ? 100 : ((items.nodeManager/items.childs.length) * 100) : 0 " :stroke-color="['#59be94', '#d1f5e6']">
                                <Icon type="checkmark-circled"></Icon>
                                        <span style="color:#999999 ">{{items.nodeManager != 0 ? ((items.nodeManager/items.childs.length) * 100) > 100 ? 100 : (((items.nodeManager/items.childs.length) * 100)|filtersNum) : 0}}%</span>
                                </Progress>
                            </div>      -->
                      </template>
                    </div>
                </div>
            </div>
        </div>
      </div>
       <LiefengModal :value="messageRegion"  @input="regionInput" title="社区组织动员体系建设情况总览" :fullscreen="true" >
          <template v-slot:contentarea>
              <House :isShow="showNumberOther" :tableData="buildTableData"></House>
          </template>
          <template v-slot:toolsbar>
            <Button style="margin: 0 5px 0 13px" :disabled="sendDisabled" type="primary" @click="sendRegion">确定</Button>
          </template>
      </LiefengModal>
      <LiefengModal
      :value="listStatus"
      @input="changeListStatus"
      
    >
        <template  v-slot:title>
        <Button style="float:right;margin:10px 40px 0 0" type="primary" icon="ios-add" @click="addForm">新增居民</Button>
      </template>
      <template  v-slot:contentarea>
        <Peoplelist :loading='loading' :tableData='tableData' :houseCode='houseCode' @getList='getList'></Peoplelist>
      </template>
      <template v-slot:toolsbar>
        <Button type="primary" @click="close">关闭</Button>
      </template>  
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "./LiefengModal";
import House from './house.vue'
import buildimage from '../../../../public/images/group_icon.png'
import Peoplelist from './peoplelist.vue'
export default {
    components:{LiefengModal,House,Peoplelist},
    props:{
        isShow:{
            type:Number,
            default:()=>{
                return 0
            }
        },
        tableData:{
            type:Array,
            default:()=>{
                return []
            }
        },
        category:{
            type:String,
            default:()=>{
                return ''
            }
        },
        tableData2:{
            type:Array,
            default:()=>{
                return []
            }
        }
    },
    data(){
        return{
            listStatus:false,
            houseCode:'',
            tableData:[],
            loading:false,
            

            messageRegion:false,
            showNumberOther:0,
            buildTableData:[],
            dataTotal:0,
            jumingTotal:0,
            buildimage:buildimage
        }
    },
    filters:{
        spliceTitle(option){
            if(option.length >10){
                return option = option.slice(0, 10) + '...'
            }else{
                return option
            }
        },
        spliceNum(option){
            let data = option
            if(data){
                data.map((item,index) =>{
                    if(item.category == 5){
                        data.slice(index,1)
                    }
                })
            }
            return data
        },
        filtersNum(options){
            if(options.indexOf('.') > -1){
                const reg = /([0-9]+\.[0-9]{2})[0-9]*/
                return options = options.replace(reg,'$1')
            }else{
                return options
            }
        }
    },
    methods:{
        open(code){
            this.$store.commit('changeGridHouseCode',code)
            this.listStatus = true
            this.getList(code)
        },
        close(){
            this.$store.commit('changeGridHoustCode','')
            this.listStatus = false
        },
        changeListStatus(val){
            if(!val){
               this.$store.commit('changeGridHoustCode','') 
            }
            this.listStatus = val
        },
        getList(houseCode){
            this.houseCode = houseCode
            this.loading = true
            this.$get('/syaa//api/syuser/pc/residentArchive/selectArchivesPage',{
                oemCode: parent.vue.oemInfo.oemCode,
                houseNo:houseCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                page: 1,
                pageSize:50,
            }).then( res => {
                if(res.code == 200){
                    this.loading = false
                    this.tableData = res.dataList
                }else{
                    this.loading = false
                    this.$Message.error({
                        background: true,
                        content: res.desc
                    });
                }
            })
        },
        addForm(){
            this.$router.push(`/linehomeaddform?isEdit=false&grid=true&houseCode=${this.houseCode}`)
        },
        openModal(items){
            this.buildTableData = []
            this.showNumberOther++
             let index = 0
            //  if(items && items.length != 0){
                 if(items.childs != null && items.childs.length != 0){
                     items.total = items.childs.length
                     items.childs.map(item =>{
                        if(items.nodeManager != 0){
                            index = index + Number(item.nodeManager)
                        }
                     })
                     items.num = index
                 }else{
                     items.total = 0
                 }
                 
            //  }
            this.buildTableData = items
            this.messageRegion = true
            ++this.showNumber

            console.log('build',this.buildTableData);
        },
        regionInput(status){
            this.messageRegion = status
        },
        sendRegion(){
            this.messageRegion = false
        },
        formatter(data){
            if(data && data.length != 0 ){
                data.map(item=>{
                    if(item.category == 3){
                        if(item.childs && item.childs.length != 0){
                            item.childs.map(items=>{
                                this.jumingTotal = this.jumingTotal + items.manager
                            })
                        }
                    }
                })
            }
        },
    },
    watch:{
        isShow:{
            handler(val){
                if(val) {
                    this.dataTotal = 0
                    this.yidataTotal = 0
                    this.jumingTotal = 0

                    if(this.tableData2 && this.tableData2.childs.length != 0){
                        this.dataTotal = this.tableData2.childs.length
                        this.tableData2.childs.map(item=>{
                            this.yidataTotal = this.yidataTotal +Number(item.nodeManager)
                        })
                    }else{
                        this.dataTotal = 0
                    }
                    
                    
                }
            }
        }
    },
    mounted(){
        // if(this.$store.state.gridHouseCode){
        //     this.open(this.$store.state.gridHouseCode)
        // }
    },
}
</script>

<style lang="less" scoped>
    .build-div{
        width: 100%;
        .title-div{
            font-size: 20px;
            font-weight: bold;
            padding-left: 20px;
        }
        .card{
            margin-top: 20px;
            width: 200px;
            text-align: center;
            .item{
                font-size: 16px;
                width: 25%;
                height: 100%;
                padding: 20px;
                
                .title{
                    text-align: center;
                    margin: 10px  ;
                }
            }
        }
         .center{
            width: 100%;
            height: 80px;
            display: flex;
            justify-content: flex-start;
            border-top: 1px solid rgb(228, 227, 227);
            border-bottom: 1px solid rgb(228, 227, 227);
            padding-top: 15px;
            margin: 20px 0;
            .desc,.white,.red,.yellow,.blue{
                margin-right: 20px;
                display: flex;
            }
            .desc{
                margin-left: 20px;
                font-size: 16px;
                line-height: 50px;
            }
            .white{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    background: #cccccc;
                    margin-right: 10px;
                }
                .right{
                    text-align: center;
                }
            }
            .red{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: #55C794;
                }
                .right{
                    text-align: center;
                }
            }
            .yellow{
                padding: 5px 0 ;
                .left{
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    margin-right: 10px;
                    background-color: #EE866B;
                }
                .right{
                    text-align: center;
                }
            }
            .blue{
                padding: 5px 0 ;
                .left{
                    width: 40px;
                    height: 40px;
                    border: 1px solid #ccc;
                    margin-right: 10px;
                    background-color: #55C794;
                }
                .right{
                    text-align: center;
                }
            }
        }
        .bottom{
        
        .bottom-view{
            display: flex;
            justify-content: flex-start;
            .left{
                    width: 180px;
                    word-break: wrap;
                    padding:10px 20px 20px 20px;
                    border: 1px solid #ccc;
                    margin-right: 20px;
                    height: 100px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-align: center;
                    &none{
                        background-color: #55C794;
                        color:white;
                    }
                }   
                .right{
                    flex: 1;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .item{
                        border: 1px solid #ccc;
                        margin: 0px 20px 20px 0;
                        width: 200px;
                        height: 100px;
                        position: relative;
                        user-select: none;
                        cursor: pointer;
                        .content{
                            position: absolute;
                            margin: 10px;
                            width: 180px;
                            height: 80px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            line-clamp: 4;
                            -webkit-box-orient: vertical;
                            text-align: center;
                        }
                    }
                    .red{
                        background-color: #55C794;
                        color: #ffffff;
                    }
                    .yellow{
                        background-color: #EE866B;
                        color: #ffffff;
                    }
                    .blue{
                        background-color: #55C794;
                        color: #ffffff;
                    }
                }
        }
        
    }
    .card{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .item{
            font-size: 16px;
            width: 380px;
            height: 160px;
            padding: 20px;
            color: #ffffff;
            border-radius: 8px;
            margin: 0 20px;
            .top-div{
                display: flex;
                justify-content: space-between;
                height:100px;
                .div-left{
                    .title{
                        opacity: 0.5;
                        font-size: 18px;
                        line-height: 32px;
                    }
                    .num{
                        font-size: 40px;
                        // line-height: 70px;
                        .span{
                            font-size: 16px;
                            line-height: 28px;
                            color: #FFFFFF;
                            opacity: 0.5;
                            padding-left: 20px;
                        }
                    }
                }
                .div-right{
                    font-size: 20px;
                    margin-right: 20px;
                    .num1{
                        padding-top: 10px;
                        // line-height: 32px;
                    }
                    .num2{
                        line-height: 32px;
                        margin-top: 20px;
                    }
                }
            }
            .bottom-div{
                height: 60px;
            }
        }
        .background1{
            background-image: linear-gradient(to right,#5770FB, #C577FF)
        }
        .background2{
            background-image: linear-gradient(to right,#40ACF7,#737CFF)
        }
        .background3{
            background-image: linear-gradient(to right,#ED6281,#F09D5E)
        }
        .background4{
            background-image: linear-gradient(to right,#3DB684,#95CF4B)
        }
    }
    .bottom-child{
        height: 100%;
        .child{
            color: #ffffff;           
           .top{
               width: 680px;
               height: 80px;
               border-radius:6px;
               display: flex;
               background:#CCCCCC;
               font-size: 20px;
               justify-content: flex-start;
               height: 80px;
               line-height: 80px;
               min-width: 680px;
               margin: 20px 0;
               padding-right: 0 20px;
           }
           .child-top{
               background: #55C794;
           }
           .name{
               padding-left: 20px;
           }
           .build{
               padding-left: 20px;
           }
           .builed{
               margin-left: 20px;
               margin-right: 20px;
           }
           .child-view{
               width: 100%;
               margin: 20px 20px 20px 0;
               display: flex;
                flex-wrap: wrap;
               .build-view{
                   width: 22%;
                   margin-right: 20px;
                   height: 176px;
                   background: #FDFFFF;
                   box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                   opacity: 1;
                   border-radius: 6px;
                   cursor: pointer;
                   user-select: none;
                   color: black;
                   margin-bottom: 20px;
                   &:hover{
                       box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
                   }
                   .item{
                       background: #cccccc;
                       border-top-left-radius: 6px;
                       border-top-right-radius: 6px;
                       display: flex;
                       height: 72px;
                       padding: 10px 20px;
                   }
                   .yellow{
                       background: #EE866B;
                   }
                   .image{
                       margin-right: 5px;
                       .img{
                           width: 34px;
                           height: 34px;
                       }
                   }
                   .text{
                       .title{
                           font-size: 16px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                       .title2{
                           font-size: 12px;
                            color: #FFFFFF;
                            opacity: 1;
                       }
                    }
                    .text-view{
                        display: flex;
                        justify-content: center;
                        padding-top: 20px;
                        .text-item{
                            width: 33.3%;
                            text-align: center;
                            .title{
                                font-size: 14px;
                                line-height: 21px;
                                color: #999999;
                            }
                            .num{
                                font-size: 14px;
                                line-height: 25px;
                                color: #393939;
                            }
                        }
                        
                    }
                    .precent{
                            height: 30px;
                            margin-left: 10px;
                        }
               }
               
           }
        }
    }
    /deep/.ivu-card{
        background: none;
    }
    /deep/.ivu-card-bordered{
        border: none;
        color: #ffffff;
    }
    /deep/.ivu-progress-text{
        color: #ffffff;
    }
    }
</style>